import { Account } from "views/account";
import { Profile } from "views/account/profile";
import { Billing } from "views/account/billing";
import { Upgrade } from "views/account/upgrade";
import { Users } from "views/account/users";
import { Password } from "views/account/password";
import { TwoFA } from "views/account/2fa";
import { APIKeyList } from "views/account/apikey/list";
import { APIKeyEditor } from "views/account/apikey/edit";
import { SanctionsView } from "views/account/sanctions";
import { Integrations } from "views/account/integrations";
import { CustomEmailsView } from "views/account/email";
import { NotificationSettingsView } from "views/account/notifications";
import { VisibilityView } from "views/account/visibility";
import { AdverseMediaView } from "views/account/adverse";
import { HubSpotSettingsView } from "views/account/hubspot_settings";
import { RemindersView } from "views/account/reminders";
import { XpmSettingsView } from "views/account/xpm_settings";
import { XpmScanNowView } from "views/account/xpm_scan_now";
import { PipedriveSettingsView } from "views/account/pipedrive_settings";
import { FYISettingsView } from "views/account/fyi_settings";
import { KarbonSettingsView } from "views/account/karbon_settings";
import { FormView } from "views/account/form";
import { FormBuilderView } from "views/account/form/builder";
import { PostVerificationFormSettings } from "views/account/form/post_verification";
import { PreVerificationFormSettings } from "views/account/form/pre_verification";
import { XpmPreVerificationForms } from "views/account/form/xpm_pre_verification";
import { XpmPreVerificationFormSettings } from "views/account/form/xpm_pre_verification_settings";
import { WebhooksView } from "views/account/webhooks";

const Routes = [
  {
    path: "/account",
    view: Account,
    layout: "app",
    permission: "user",
    title: "Your Account",
  },
  {
    path: "/account/profile",
    view: Profile,
    layout: "app",
    permission: "user",
    title: "Your Profile",
  },
  {
    path: "/account/password",
    view: Password,
    layout: "app",
    permission: "user",
    title: "Change Password",
  },
  {
    path: "/account/2fa",
    view: TwoFA,
    layout: "app",
    permission: "user",
    title: "Manage 2FA",
  },
  {
    path: "/account/billing",
    view: Billing,
    layout: "app",
    permission: "owner",
    title: "Billing",
  },
  {
    path: "/account/upgrade",
    view: Upgrade,
    layout: "app",
    permission: "owner",
    title: "Upgrade",
  },
  {
    path: "/account/users",
    view: Users,
    layout: "app",
    permission: "admin",
    title: "Users",
  },
  {
    path: "/account/apikeys",
    view: APIKeyList,
    layout: "app",
    permission: "developer",
    title: "API Keys",
  },
  {
    path: "/account/apikeys/create",
    view: APIKeyEditor,
    layout: "app",
    permission: "developer",
    title: "API Keys",
  },
  {
    path: "/account/apikeys/edit",
    view: APIKeyEditor,
    layout: "app",
    permission: "developer",
    title: "API Keys",
  },
  {
    path: "/account/integrations",
    view: Integrations,
    layout: "app",
    permission: "admin",
    title: "Integrations",
  },
  {
    path: "/account/integrations/hubspot",
    view: HubSpotSettingsView,
    layout: "app",
    permission: "admin",
    title: "HubSpot Settings",
  },
  {
    path: "/account/integrations/xpm",
    view: XpmSettingsView,
    layout: "app",
    permission: "admin",
    title: "XPM Settings",
  },
  {
    path: "/account/integrations/xpm/scan-now",
    view: XpmScanNowView,
    layout: "app",
    permission: "admin",
    title: "XPM Scan Now",
  },
  {
    path: "/account/integrations/pipedrive",
    view: PipedriveSettingsView,
    layout: "app",
    permission: "admin",
    title: "Pipedrive Settings",
  },
  {
    path: "/account/integrations/fyi",
    view: FYISettingsView,
    layout: "app",
    permission: "admin",
    title: "FYI Settings",
  },
  {
    path: "/account/integrations/karbon",
    view: KarbonSettingsView,
    layout: "app",
    permission: "admin",
    title: "Karbon Settings",
  },
  {
    path: "/account/sanctions",
    view: SanctionsView,
    permission: "admin",
    title: "Sanctions",
    layout: "app",
  },
  {
    path: "/account/custom-emails",
    view: CustomEmailsView,
    permission: "admin",
    title: "Custom Emails",
    layout: "app",
  },
  {
    path: "/account/notifications",
    view: NotificationSettingsView,
    permission: "admin",
    title: "Notifications",
    layout: "app",
  },
  {
    path: "/account/visibility",
    view: VisibilityView,
    permission: "admin",
    title: "Visibility",
    layout: "app",
  },
  {
    path: "/account/adverse-media",
    view: AdverseMediaView,
    permission: "admin",
    title: "Adverse Media",
    layout: "app",
  },
  {
    path: "/account/reminders",
    view: RemindersView,
    permission: "admin",
    title: "Reminders",
    layout: "app",
  },
  {
    path: "/account/forms",
    view: FormView,
    title: "Form",
    layout: "app",
  },
  {
    path: "/account/form/:id",
    view: FormBuilderView,
    title: "Form Builder",
    layout: "app",
  },
  {
    path: "/account/post-form",
    view: PostVerificationFormSettings,
    title: "Post Verification Form",
    layout: "app",
  },
  {
    path: "/account/pre-verification",
    view: PreVerificationFormSettings,
    title: "Pre Verification Form",
    layout: "app"
  },
  {
    path: "/account/pre-verification/xpm",
    view: XpmPreVerificationForms,
    title: "XPM Pre Verification Forms",
    layout: "app"
  },
  {
    path: "/account/pre-verification/xpm/:id",
    view: XpmPreVerificationFormSettings,
    title: "XPM Pre Verification Form Settings",
    layout: "app"
  },
  {
    path: "/account/webhooks",
    view: WebhooksView,
    title: "Webhooks",
    layout: "app",
  },
];

export default Routes;
