import { Button } from "components/lib";
import React, { Fragment, useState } from "react";
import Axios from "axios";

export function XpmScanNowView(props) {
  const [apiResponse, setApiResponse] = useState("");
  const scanNow = async () => {
    console.log("scan now");
    const response = await Axios.post("/api/integration/xpm/scan-now");
    console.log(response);
    setApiResponse(response.data.message);
  };

  return (
    <Fragment>
      <h1>Scan Now</h1>
      <Button text="Scan" action={scanNow} />
      <p>{apiResponse}</p>
    </Fragment>
  );
}
